// react
import React, { FC, useEffect, useRef } from 'react';

// tinymce-react
import { Editor } from '@tinymce/tinymce-react';

interface ITinyEditor {
  value: string;
  setFieldValue?: (val: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  toolbarHidden?: boolean;
}

export const TinyEditor: FC<ITinyEditor> = ({
  value,
  setFieldValue,
  placeholder,
  readOnly,
  toolbarHidden,
}) => {
  const editorRef = useRef<any>(null);

  useEffect(() => {
    const handleDrop = (e: DragEvent) => {
      if (
        editorRef.current &&
        editorRef.current.getContainer &&
        !editorRef.current.getContainer().contains(e.target as Node)
      ) {
        e.preventDefault();
        e.stopPropagation();
        console.log('Drop event prevented outside the editor');
      }
    };

    const handleDragOver = (e: DragEvent) => {
      if (
        editorRef.current &&
        editorRef.current.getContainer &&
        !editorRef.current.getContainer().contains(e.target as Node)
      ) {
        e.preventDefault();
        e.stopPropagation();
        console.log('DragOver event prevented outside the editor');
      }
    };

    document.addEventListener('drop', handleDrop);
    document.addEventListener('dragover', handleDragOver);

    return () => {
      document.removeEventListener('drop', handleDrop);
      document.removeEventListener('dragover', handleDragOver);
    };
  }, []);

  return (
    <Editor
      licenseKey="gpl"
      tinymceScriptSrc="/tinymce/tinymce.min.js"
      onInit={(_evt, editor) => (editorRef.current = editor)}
      initialValue={placeholder}
      value={value}
      onEditorChange={(newValue, editor) => {
        if (setFieldValue) {
          setFieldValue(newValue);
        }
      }}
      init={{
        height: 500,
        directionality: 'ltr',
        menubar: readOnly
          ? false
          : 'file edit view insert format tools table help',
        plugins: readOnly
          ? []
          : [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'preview',
              'help',
              'wordcount',
              'image',
              'emoticons',
            ],
        toolbar:
          readOnly || toolbarHidden
            ? false
            : `undo redo | formatselect | bold italic underline strikethrough emoticons|
              forecolor backcolor | alignleft aligncenter alignright alignjustify |
              outdent indent | numlist bullist table | blockquote subscript superscript |
              code | removeformat | link image media | fullscreen preview`,
        a11y_advanced_options: true,
        menu:
          readOnly || toolbarHidden
            ? {}
            : {
                file: {
                  title: 'File',
                  items: 'newdocument restoredraft | preview | print',
                },
                edit: {
                  title: 'Edit',
                  items:
                    'undo redo | cut copy paste pastetext | selectall | searchreplace',
                },
                view: {
                  title: 'View',
                  items:
                    'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen',
                },
                insert: {
                  title: 'Insert',
                  items:
                    'image link media inserttable | charmap emoticons hr | pagebreak nonbreaking anchor | insertdatetime',
                },
                format: {
                  title: 'Format',
                  items:
                    'bold italic underline strikethrough superscript subscript codeformat | styles blocks align | removeformat',
                },
                tools: {
                  title: 'Tools',
                  items: 'spellchecker spellcheckerlanguage | code',
                },
                table: {
                  title: 'Table',
                  items:
                    'inserttable | cell row column | tableprops deletetable',
                },
                help: {
                  title: 'Help',
                  items: 'help',
                },
              },
        content_style:
          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        automatic_uploads: true,
        file_picker_types: 'image',
        paste_data_images: true,
        images_reuse_filename: true,
        file_picker_callback: (callback, value, meta) => {
          if (meta.filetype === 'image') {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.onchange = function () {
              if (input.files && input.files.length > 0) {
                const file = input.files[0];
                const reader = new FileReader();
                reader.onload = function () {
                  if (reader.result) {
                    const id = 'blobid' + new Date().getTime();
                    const blobCache = editorRef.current.editorUpload.blobCache;
                    const base64 = reader.result.toString().split(',')[1];
                    const blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);

                    callback(blobInfo.blobUri(), { title: file.name });
                  }
                };
                reader.readAsDataURL(file);
              }
            };

            input.click();
          }
        },
      }}
    />
  );
};

export default TinyEditor;
