import React, { FC } from 'react';

// components
import { Box, Typography } from '@mui/material';

// custom components
import TinyEditor from '../TinyMCE/TinyEditor';

// types
import { ImageLabelTheme } from '../../interfaces/requestParams';
import { PostType } from '../../interfaces/post';

// helpers
import dayjs from 'dayjs';

//styles
import styles from './styles.module.scss';

interface IPostDetails {
  post: PostType | null;
}

const PostDetails: FC<IPostDetails> = ({ post }) => {
  return (
    <Box>
      <Box sx={{ mb: '40px' }}>
        <label className={styles.label}>Main Image</label>
        <Box style={{ margin: '20px auto 6px auto' }}>
          <Box position="relative">
            <img
              src={post?.mainImage}
              alt="PreviewImage"
              className={styles.postImage}
            />
            {post?.mainImageLabel && (
              <Box
                className={styles.postLabelContainer}
                style={{
                  backgroundColor:
                    post.mainImageLabelTheme === ImageLabelTheme.DARK
                      ? 'rgba(0, 0, 0, 0.35)'
                      : 'rgba(255, 255, 255, 0.35)',
                }}
              >
                <Typography className={styles.postLabelText}>
                  {post?.mainImageLabel}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ mb: '40px', display: 'flex', justifyContent: 'flex-start' }}>
        <Box width={'100%'}>
          <label className={styles.label}>Label</label>
          <Typography className={styles.content}>
            {post?.mainImageLabel}
          </Typography>
        </Box>
        <Box width={'100%'}>
          <label className={styles.label}>Label Type</label>
          <Typography className={styles.content}>
            {post?.mainImageLabelTheme}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ mb: '40px', display: 'flex', justifyContent: 'flex-start' }}>
        <Box width={'100%'}>
          <label className={styles.label}>Title</label>
          <Typography className={styles.content}>{post?.title}</Typography>
        </Box>
        <Box width={'100%'}>
          <label className={styles.label}>Type</label>
          <Typography className={styles.content}>{post?.type}</Typography>
        </Box>
      </Box>

      <Box sx={{ mb: '40px' }}>
        <label className={styles.label}>Publish Date</label>
        <Typography className={styles.content}>
          {dayjs(post?.publishDate as string).format('DD MMM YYYY')}
        </Typography>
      </Box>

      {post?.content && (
        <TinyEditor value={post.content} toolbarHidden readOnly />
      )}
    </Box>
  );
};

export default PostDetails;
